/* nav-bar */
.navbarsection .navbar{
    position: fixed; 
    top: 0; 
    width: 100%; 
    z-index: 99;
    background-color: #443516;
}
/* .navbarsection .navbar .logo{
    width: 80%;
} */
.navbarsection .container{
    display:  -webkit-inline-box;
}
.navbarsection .scrollbox{
    display: flex;
    list-style: none;
    /* margin-right: 190px; */
}
.navbarsection .navbar .nav-link{
    font-size: 18px;
    font-weight: 600;
    color:#CED2C8;
}
 .active a{
    color: #D2A13B !important;
}
.navbarsection .nav-link:hover{
    color: #D2A13B;
}
.navbarsection .icon{
    color:#CED2C8;
    font-size: 175%;
}
.navbarsection .icons{
    display: flex !important;
    list-style: none;
    margin-left: 260px;
}
.navbarsection .icon:hover{
    color:#D2A13B ;
   
}
.navbar-toggler{
    margin-left: 47%;
}
/*margin-left: 210px; */
@media only screen and (max-width: 1300px) and (min-width: 769px)  {
    .navbarsection .icons{
        margin-left: 162px;
    }
    .navbarsection .navbar .logo{
        width: 25%;
    }
}
@media only screen and (max-width: 769px) and (min-width: 320px)  {
    .navbarsection .container{
        display: flow-root;
    }
    .navbarsection .navbar .logo{
        width: 30%;
    }
    .navbarsection .scrollbox{
        display: block;
        list-style: none;
    }
    .navbarsection .icons{
        display: flex !important;
        list-style: none;
        margin-left: 20px !important;
    }
    .navbar-toggler{
        margin-left: 30%;
    }
    .navbar-toggler-icon{
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,244,244, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important ;
        /* color: rgba(255, 244, 244, 0.55); */
    }
    /*  */
}

/* hero ============================*/
.herosection{
    height: 100vh;
    background-image: url(../../Assets/Images/hero-gif.gif);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.herosection .content{
    margin-top: 7rem;
}
.herosection p{
    font-size: 40px;
    color: #443516;
    font-weight: 200;
    margin-left: 40%;
}
.herosection p span{
    font-size: 40px;
    color: #443516;
    font-weight: 700;
}
@media only screen and (max-width: 769px) and (min-width: 320px)  {
    .herosection{
        padding: 0 !important;
        height: 375px;
    }
    .herosection .content{
        margin-top: -25px;
        padding-top: 60px;
    }
    .herosection img{
        margin-top: 88px !important;
    }
    .herosection p{
        font-size: 20px;
        color: #443516;
        font-weight: 200;
        margin-left: 10%;
    }
    .herosection p span{
        font-size: 28px;
        color: #443516;
        font-weight: 700;
    }
}
/* About style--------------------------- */
.ourvision{
    background-image: url(../../Assets/Images/bg-test.svg);
    /* height: 200vh; */
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
}
.ourvision .data{
    margin-top: 23%;
}
.ourvision .data .title-font{
    font-size: 45px;
    color: #443516;
    font-weight: 700;
}
.ourvision .data p{
    color: #443516;
    font-size: 26px;
    font-weight: 400;
}
.ourvision .line-title{
    width: 80px;
    display: flex;
    margin-left: 1%;
    height: 10px;
    background-color: #D2A13B;
}

@media only screen and (max-width: 769px) and (min-width: 320px)  {
    .ourvision{
        padding-top: 0 ;
    }
    .ourvision .data p{
        color: #443516;
        font-size: 20px;
        font-weight: 400;
    }
}
/* coming */
.coming{
    background-image: url(../../Assets/Images/coming-bg.svg);
    height: 80vh;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
}
.coming h3{
    margin-top: 8%;
    color: #443516;
    font-size: 275%;
    font-weight: 700;
}
@media only screen and (max-width: 769px) and (min-width: 320px)  {
    .coming{
        height: 40%;
    }
    .coming h3{
        margin-top: 8%;
        color: #443516;
        font-size: 150%;
        font-weight: 700;
    }
}
/* team========== */
.team{
    background-image: url(../../Assets/Images/bg-team.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    /* height: 100vh; */
}
.team .line-title{
    width: 80px;
    display: flex;
    margin-left: 43%;
    height: 10px;
    background-color: #D2A13B;
}
.team .title .title-font{
    font-size: 45px;
    color: #443516;
    font-weight: 700;
}
.team .slideritem{
    margin-bottom: 80px;
}
.team .slick-list{
   margin: 0px 20px;
}
.team .img-t{
    margin-left: 25%;
}
.team .inside{
    border: none;
    border-radius: 25px;
    background-color: #fff;
    text-align: center;
    width: 95% ;
    height: 30rem;
}
.team .inside h3{
    color: #8E5204;
    font-weight: 700;
}
.team .inside label{
    color:#BD8031;
    font-weight: 600;
}
.team .inside p{
    font-size: #000;
    font-weight: 500;
}
.team .slick-dots li.slick-active button:before{
    opacity: .75;
    color: #8E5204;
    font-size: 16px;
}
.team .slick-dots li button:before{
    opacity: .75;
    color: #B5B7AC;
    font-size: 16px;
}
.team .slick-prev:before ,
.team .slick-next:before {
    color: #8E5204;
}

@media (max-width: 768px) {
    .team .title .title-font{
        font-size: 30px;
        color: #443516;
        font-weight: 700;
    }
.team .inside h3{
    color: #8E5204;
    font-weight: 700;
    font-size: 12px;
}
.team .inside label{
    color:#BD8031;
    font-weight: 600;
    font-size: 10px;

}
.team .inside p{
    font-size: #000;
    font-weight: 500;
    font-size: 10px;

}
}
/* sponsers */
.sponsers{
    /* background-image: url(../../Assets/Images/sp-bg.png); */
    /* background-image: url(../../Assets/Images/h-sp-h.svg); */

    /* background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll; */
    position: relative;
    /* height: 100vh; */
}
.sponsers .circle1-sp{
    position: absolute;
    top: -142px;
    z-index: -1;
    left: 3px;
}
.sponsers .circle2-sp{
    position: absolute;
    top: -248px;
    z-index: -1;
    right: 2px;
}
.sponsers .sliders{
    padding-top: 150px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: auto;
}
.sponsers .sliders .title-font{
    font-size: 45px;
    color: #443516;
    font-weight: 700;
}

/* .sponsers .slider-trucks{
    animation: scroll 10s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(250px * 14);
    /* width:200%; */
/* } */
/* .slider-trucks:hover{
    animation-play-state: paused;
}  */
/* @keyframes scroll {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(calc(-250px * 9 ));
    }
    /* 0% { left: 0; }
    100% { left: -100%; } */
/* }  */
/* .sponsers .sliders .slide{
    height: 100px;
    width: 250px;
} */
/* test */

.sponsers .sliders .line-title{
    width: 80px;
    display: flex;
    margin-left: 41%;
    height: 10px;
    background-color: #D2A13B;
}

.sponsers .data-info p{
    font-size: 11px;
    color: #443516;
    font-weight: 600;
}
.sponsers .img-line{
    background-image: url(../../Assets/Images/bg-fqa.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: scroll;
    height: 8vh;
}
.sponsers .faq .title-font{
    font-size: 45px;
    color: #443516;
    font-weight: 700;
}
.sponsers .faq .line-title{
    width: 80px;
    display: flex;
    margin-left: 47%;
    height: 10px;
    background-color: #D2A13B;
}
.accordion-button::after{
    background-size: 1rem !important;
}
.accordion-item{
    border: 0;
    margin-bottom: 12px;
}
.accordion-item .accordion-button {
    background-color: #F7F4E9;
    color: #2D2E2E;
    font-size: 24px;
    font-weight: 700;
}
.accordion-body{
    background-color: #F7F4E9;
    border: 0;
    text-align:initial;
    margin-bottom: 30px;

}

@media (max-width: 768px) {
    .sponsers .sliders .title-font{
        font-size: 30px;
        color: #443516;
        font-weight: 700;
    }
    
.sponsers .circle1-sp {
    position: absolute;
    top: -144px;
    z-index: -1;
    left: -58px;
}
.sponsers .circle2-sp {
    display: none;
}
    .sponsers .data-info p{
        font-size: 12px;
        color: #443516;
        font-weight: 600;
    }
    .sponsers .faq .line-title{
        margin-left: 38%;

    }
    .accordion-item .accordion-button {
        background-color: #F7F4E9;
        color: #2D2E2E;
        font-size: 20px;
    }
}